import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2d74564c = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _cb349540 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _b79670c2 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _42fdb32a = () => interopDefault(import('../pages/member.vue' /* webpackChunkName: "pages/member" */))
const _093e59f2 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _650fce7c = () => interopDefault(import('../pages/realtime.vue' /* webpackChunkName: "pages/realtime" */))
const _5177d23c = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _1358fdb4 = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _390d7af1 = () => interopDefault(import('../pages/topic/index.vue' /* webpackChunkName: "pages/topic/index" */))
const _4fc44250 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _4f5f133d = () => interopDefault(import('../pages/video/index.vue' /* webpackChunkName: "pages/video/index" */))
const _4fbb6cab = () => interopDefault(import('../pages/brands/meridank.vue' /* webpackChunkName: "pages/brands/meridank" */))
const _e00ff114 = () => interopDefault(import('../pages/brands/sinyi.vue' /* webpackChunkName: "pages/brands/sinyi" */))
const _17127a56 = () => interopDefault(import('../pages/amp/article/_id.vue' /* webpackChunkName: "pages/amp/article/_id" */))
const _3ea8a0a0 = () => interopDefault(import('../pages/article/_id.vue' /* webpackChunkName: "pages/article/_id" */))
const _636db210 = () => interopDefault(import('../pages/search/_q.vue' /* webpackChunkName: "pages/search/_q" */))
const _52c99544 = () => interopDefault(import('../pages/series-sort/_id.vue' /* webpackChunkName: "pages/series-sort/_id" */))
const _06ef8599 = () => interopDefault(import('../pages/topic/_id.vue' /* webpackChunkName: "pages/topic/_id" */))
const _45ba7e36 = () => interopDefault(import('../pages/video/_id.vue' /* webpackChunkName: "pages/video/_id" */))
const _5d35d8fd = () => interopDefault(import('../pages/author/_role/_name.vue' /* webpackChunkName: "pages/author/_role/_name" */))
const _b4b104f0 = () => interopDefault(import('../pages/category/_mainCategory/_subCategory.vue' /* webpackChunkName: "pages/category/_mainCategory/_subCategory" */))
const _4d2c3111 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _2d74564c,
    meta: {},
    alias: ["/amp/about"],
    name: "about"
  }, {
    path: "/brands",
    component: _cb349540,
    meta: {},
    alias: ["/amp/brands"],
    name: "brands"
  }, {
    path: "/contact",
    component: _b79670c2,
    meta: {},
    alias: ["/amp/contact"],
    name: "contact"
  }, {
    path: "/member",
    component: _42fdb32a,
    meta: {},
    alias: ["/amp/member"],
    name: "member"
  }, {
    path: "/privacy",
    component: _093e59f2,
    meta: {},
    alias: ["/amp/privacy"],
    name: "privacy"
  }, {
    path: "/category/%E6%9C%80%E6%96%B0",
    component: _650fce7c,
    meta: {},
    alias: ["/amp/category/%E6%9C%80%E6%96%B0"],
    name: "realtime"
  }, {
    path: "/search",
    component: _5177d23c,
    meta: {},
    alias: ["/amp/search"],
    name: "search"
  }, {
    path: "/sitemap",
    component: _1358fdb4,
    meta: {},
    alias: ["/amp/sitemap"],
    name: "sitemap"
  }, {
    path: "/topic",
    component: _390d7af1,
    meta: {},
    alias: ["/amp/topic"],
    name: "topic"
  }, {
    path: "/unsubscribe",
    component: _4fc44250,
    meta: {},
    alias: ["/amp/unsubscribe"],
    name: "unsubscribe"
  }, {
    path: "/video",
    component: _4f5f133d,
    meta: {},
    alias: ["/amp/video"],
    name: "video"
  }, {
    path: "/brands/meridank",
    component: _4fbb6cab,
    meta: {},
    alias: ["/amp/brands/meridank"],
    name: "brands-meridank"
  }, {
    path: "/brands/sinyi",
    component: _e00ff114,
    meta: {},
    alias: ["/amp/brands/sinyi"],
    name: "brands-sinyi"
  }, {
    path: "/amp/article/:id?",
    component: _17127a56,
    meta: {"amp":true},
    alias: [],
    name: "amp-article-id"
  }, {
    path: "/article/:id?",
    component: _3ea8a0a0,
    meta: {},
    alias: ["/amp/article/:id?"],
    name: "article-id"
  }, {
    path: "/search/:q",
    component: _636db210,
    meta: {},
    alias: ["/amp/search/:q"],
    name: "search-q"
  }, {
    path: "/series-sort/:id?",
    component: _52c99544,
    meta: {},
    alias: ["/amp/series-sort/:id?"],
    name: "series-sort-id"
  }, {
    path: "/topic/:id",
    component: _06ef8599,
    meta: {},
    alias: ["/amp/topic/:id"],
    name: "topic-id"
  }, {
    path: "/video/:id",
    component: _45ba7e36,
    meta: {},
    alias: ["/amp/video/:id"],
    name: "video-id"
  }, {
    path: "/author/:role?/:name?",
    component: _5d35d8fd,
    meta: {},
    alias: ["/amp/author/:role?/:name?"],
    name: "author-role-name"
  }, {
    path: "/category/:mainCategory?/:subCategory?",
    component: _b4b104f0,
    meta: {},
    alias: ["/amp/category/:mainCategory?/:subCategory?"],
    name: "category-mainCategory-subCategory"
  }, {
    path: "/",
    component: _4d2c3111,
    meta: {},
    alias: ["/amp/"],
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
